import React, { Component } from 'react'
import { Fragment } from 'react'
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import AppURL from '../../api/AppURL';
import axios from 'axios'
import cogoToast from 'cogo-toast';


export class OrderList extends Component {
     constructor() {
          super();
          this.state = {
               ProductData: [],
               isLoading: "",
               mainDiv: "d-none",
               email: "",
               show: false,
               name: "",
               rating: "",
               comment: "",
               product_name: "",
               product_code: "",
               check_review: "Yes",
               ReviewModal: false,
               PageRefreshStatus:false,

          }
     }


     componentDidMount() {
          let email = this.props.user.email;
          axios.get(AppURL.OrderListByUser(email)).then(response => {

               this.setState({
                    ProductData: response.data,
                    isLoading: "d-none",
                    mainDiv: " "
               });

          }).catch(error => {
               cogoToast.error("For some reason, the data cannot be received from the database", { position: 'top-right' });

          });

     }

     ReviewModalOpen = (product_code, product_name) => {
          this.setState({ ReviewModal: true, product_code: product_code, product_name: product_name })
     };


     ReviewModalClose = () => {
          this.setState({ ReviewModal: false })
     };
   

     RatingOnChange = (event) => {
          let rating = event.target.value;
          this.setState({ rating: rating })
     }

     CommentOnChanage = (event) => {
          let comment = event.target.value;
          this.setState({ comment: comment })
     }


     PostReview = () => {

          let product_code = this.state.product_code;
          let product_name = this.state.product_name;
          let rating = this.state.rating;
          let comment = this.state.comment;
          let check_review = "Yes";
          let name = this.props.user.name
          let email = this.props.user.email

          if (name.length === 0) {
               cogoToast.error("Name Is Required", { position: 'top-right' });
          }
          else if (rating.length === 0 || rating.length === 'Choose') {
               cogoToast.error("Rating Is Required", { position: 'top-right' });
          }
          else if (comment.length === 0) {
               cogoToast.error("Comment Is Required", { position: 'top-right' });
          }

          else if (comment.length > 150) {
               cogoToast.error("Comments can't more then 150 character", { position: 'top-right' });
          }
          else {

               let MyFromData = new FormData();
               MyFromData.append('product_code', product_code)
               MyFromData.append('product_name', product_name)
               MyFromData.append('reviewer_name', name)
               MyFromData.append('reviewer_photo', "")
               MyFromData.append('reviewer_rating', rating)
               MyFromData.append('reviewer_comments', comment)
               MyFromData.append('email', email)


               let MyCheckData = new FormData();
               MyCheckData.append('check_review', check_review)



               axios.post(AppURL.PostReview, MyFromData).then(response => {

                    if (response.data === 1) {
                         cogoToast.success("Review Submitted", { position: 'top-right' });
                         this.ReviewModalClose();
                    } else {
                         cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
                    }
               }).catch(error => {
                    cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });

               });
               axios.post(AppURL.PutReviewCheck(email,product_code),MyCheckData).then(response => {
                    this.setState({PageRefreshStatus:true})       


               }).catch(error => {

                    cogoToast.error("Currently, the database is not available", { position: 'top-right' });
               });
          }  
     }
     PageRefresh =() => {
          if(this.state.PageRefreshStatus===true){
               let URL = window.location;
               return (
                    <Redirect to={URL} />
               )
          }
     }
  

     render() {
          if (!localStorage.getItem('token')) {
               return <Redirect to="/login" />
          }
          const MyList = this.state.ProductData;
          const MyView = MyList.map((ProductList, i) => {
               let button;
               if (ProductList.check_review !== "Yes") {
                    button = <Button onClick={this.ReviewModalOpen.bind(this, ProductList.product_code, ProductList.product_name)} className="btn btn-danger mb-2">Post Review </Button>

               }

               return <div>
                    <Row>
                         <Col md={3} lg={3} sm={6} xs={6}>
                              <img className="cart-product-img" src={ProductList.image} />
                         </Col>
                         <Col md={6} lg={6} sm={6} xs={6}>
                              <h5 className="product-name">{ProductList.product_name}</h5>
                              <h6> Quantity = {ProductList.quantity} </h6>
                              <p>{ProductList.size} | {ProductList.color}</p>
                              <h6>Price = {ProductList.unit_price} x {ProductList.quantity} = {ProductList.total_price}$</h6>
                              <h6>Stauts = {ProductList.order_status} </h6>
                              {button}
                         </Col>
                         <hr></hr>
                    </Row>
               </div>

          });
          return (
               <Fragment>
                    <Container>
                         <div className="section-title text-center mb-55"><h2>Order History By ( {this.props.user.name} )</h2>

                         </div>
                         <Card >
                              <Card.Body>
                                   <Row>
                                        {MyView}
                                   </Row>
                              </Card.Body>
                         </Card>
                    </Container>
                    <Modal show={this.state.ReviewModal} onHide={this.ReviewModalClose}>
                         <Modal.Header closeButton>
                              <h6><i className="fa fa-bell"></i> Post Your Review     </h6>
                         </Modal.Header>
                         <Modal.Body>
                              {/* <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                   <label className="form-label">Your Name</label>
                                   <input onChange={this.nameOnChange} className="form-control" type="text" placeholder={this.props.user.name} />
                              </div> */}
                              <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                   <label className="form-label">Select Product Rating</label>
                                   <select onChange={this.RatingOnChange} className="form-control">
                                        <option value="">Choose</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                   </select>
                              </div>

                              <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                   <label className="form-label">Your Comment</label>
                                   <textarea onChange={this.CommentOnChanage} rows={4} className="form-control" type="text" placeholder="Your Comment" />
                              </div>

                         </Modal.Body>
                         <Modal.Footer>
                              <Button variant="secondary" onClick={this.PostReview}>
                                   Post
                              </Button>
                              <Button variant="secondary" onClick={this.ReviewModalClose}>
                                   Close
                              </Button>
                         </Modal.Footer>
                    </Modal>
                    {this.PageRefresh()}
               </Fragment >
          )
     }
}

export default OrderList




