import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AppURL from '../../api/AppURL';
import axios from 'axios'
import cogoToast from 'cogo-toast';

class Favourite extends Component {

    constructor() {
        super();
        this.state = {
            ProductData: [],
            isLoading: "",
            mainDiv: "d-none",
            PageRefreshStatus:false,
        }
    }

    componentDidMount() {
        window.scroll(0,0)
        axios.get(AppURL.FavouriteList(this.props.user.email)).then(response => {
            this.setState({
                ProductData: response.data,
                isLoading: "d-none",
                mainDiv: " "
            });
        }).catch(error => {
            
        });
    }

    removeItem = (event) => {
        let product_code = event.target.getAttribute('data-code');
        let email = this.props.user.email

        axios.get(AppURL.FavouriteRemove(product_code,email)).then(response =>{               
            cogoToast.success("Product Item Remove",{position:'top-right'});   
            this.setState({PageRefreshStatus:true})       

        }).catch(error=>{
             cogoToast.error("Your Request is not done ! Try Aagain",{position:'top-right'});
        });

   } // end Remove Item Mehtod 


   PageRefresh =() => {
        if(this.state.PageRefreshStatus===true){
             let URL = window.location;
             return (
                  <Redirect to={URL} />
             )
        }
   }


    render() {

        if(!localStorage.getItem('token')){
            return <Redirect to="/login" />
       }

        const FevList = this.state.ProductData;
        const MyView = FevList.map((ProductList, i) => {
            return <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
                <Card className="image-box card w-100">
                    <img className="center w-75" src={ProductList.image} />
                    <Card.Body>
                        <p className="product-name-on-card">{ProductList.product_name}</p>

                        <Button onClick={this.removeItem} data-code={ProductList.product_code} className='btn btn-am'><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>
                    </Card.Body>
                </Card>
            </Col>
        });
        return (
            <Fragment>
                <Container className="text-center" fluid={true}>
                    <div className="section-title text-center mb-55 "><h2> MY FAVOURITE ITEMS</h2>
                        <p>Some Of Our Exclusive Collection, You May Like</p>
                    </div>

                    <Row>
                        {MyView}
                    </Row>
                </Container>
                {this.PageRefresh()}
            </Fragment>
        )
    }
}
export default Favourite





















//     render() {
//         return (
//             <Fragment>
//                 <Container className="text-center" fluid={true}>
//                     <div className="section-title text-center mb-55"><h2> MY FAVOURITE ITEMS</h2>
//                     </div>

//                     <Row>
//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection1.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Striped Men Hooded Neck Red</p>
//                                     <p className="product-price-on-card">Price : $120</p>
//                                     <Button className='btn btn-am'><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection2.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Striped Men Round Neck Maroon, Grey</p>
//                                     <p className="product-price-on-card">Price : $120</p>
//                                     <Button className='btn btn-am'><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection3.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Color Block Men Round Neck Grey</p>
//                                     <p className="product-price-on-card">Price : $120</p>
//                                     <Button className='btn btn-am'><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection4.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
//                                     <p className="product-price-on-card">Price : $120</p>
//                                     <Button className='btn btn-am'><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                     </Row>
//                 </Container>
//             </Fragment>

//         )
//     }
// }

// export default Favourite
