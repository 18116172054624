import React, { Component, Fragment } from 'react';
import { Container, Row, Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppURL from '../../api/AppURL';
import axios from 'axios'

class SuggestedProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProductData: [],
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
  
    componentDidMount() {
        let subcategory = this.props.subcategory;
        axios.get(AppURL.SimilarProduct(subcategory)).then(response => {
            this.setState({ ProductData: response.data });
        }).catch(error => {
        });
    }
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 400,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        };
        const MyList = this.state.ProductData;
        if (MyList.length > 0) {
            const MyView = MyList.map((ProductList, i) => {
                let Delivery;
                if (ProductList.delivery === "free" || ProductList.delivery === "Free") {
                    Delivery = <p className="product-delivery-on-card">Free Delivery</p>
                }

                if (ProductList.special_price === null || ProductList.special_price === "") {
                    return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                        <Link className="text-link" to={"/productdetails/" + ProductList.id}style={{ textDecoration: 'none' }} >
                            <Card className="image-box card">
                                <img className="center" src={ProductList.image} alt="SuggestedProduct" />
                                <Card.Body>
                                    <p className="product-name-on-card">{ProductList.title}</p>
                                    <p className="product-price-on-card">Price : ${ProductList.price}</p>
                                    {Delivery}
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                } else {
                    return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                        <Link className="text-link" to={"/productdetails/" + ProductList.id}style={{ textDecoration: 'none' }} >
                            <Card className="image-box card">
                                <img className="center" src={ProductList.image} alt="SuggestedProduct"/>
                                <Card.Body>
                                    <p className="product-name-on-card">{ProductList.title}</p>
                                    <p className="product-price-on-card">Price : <strike className="text-secondary">${ProductList.price}</strike> ${ProductList.special_price}</p>
                                    {Delivery}
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                }

            });

            return (
                <Fragment>
                    <Container className="text-center" fluid={true}>
                        <div className="section-title text-center mb-55"><h2>YOU MAY ALSO LIKE &nbsp;
                            <a className="btn btn-sm ml-2 site-btn" onClick={this.previous} href={() => false}><FontAwesomeIcon icon={faAngleLeft} size="lg" /></a>&nbsp;
                            <a className="btn btn-sm ml-2 site-btn" onClick={this.next} href={() => false}><FontAwesomeIcon icon={faAngleRight} size="lg" /></a>&nbsp;

                        </h2>
                        </div>

                        <Row>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {MyView}
                            </Slider>
                        </Row>

                    </Container>

                </Fragment >
            )

        } // end if conditon 
        else {


            return (
                <Fragment>
                    <Container className="text-center" fluid={true}>
                        <div className="section-title text-center mb-55"><h2>YOU MAY ALSO LIKE </h2>
                            <p>Some Of Our Exclusive Collection, You May Like</p>
                        </div>

                        <p>There have no similar product </p>

                    </Container>
                </Fragment>
            )
        } // end else 
    }
}

export default SuggestedProduct



















//         return (
//             <Fragment>
//                 <Container className="text-center" fluid={true}>
//                     <div className="section-title text-center mb-55"><h2>YOU MAY ALSO LIKE &nbsp;
//                         <a className="btn btn-sm ml-2 site-btn" onClick={this.previous} href={() => false}><FontAwesomeIcon icon={faAngleLeft} size="lg" /></a>
//                         &nbsp;
//                         <a className="btn btn-sm ml-2 site-btn" onClick={this.next} href={() => false}><FontAwesomeIcon icon={faAngleRight} size="lg" /></a>
//                         &nbsp;

//                     </h2>
//                     </div>

//                     <Row>
//                         <Slider ref={c => (this.slider = c)} {...settings}>
//                             <div>
//                                 <Link to="/">
//                                     <Card className="image-box card">
//                                         <img className="center"
//                                             src={require('../../../src/assets/images/Mobil1.jpeg')} alt="Mobile Phone" />
//                                         <Card.Body>
//                                             <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                             <p className="product-price-on-card">Price : $120</p>

//                                         </Card.Body>
//                                     </Card>
//                                 </Link>

//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Mobil2.jpeg')} alt="Mobile Phone" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Mobil3.jpeg')} alt="Mobile Phone" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Mobil4.png')} alt="Mobile Phone" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Mobil5.jpeg')} alt="Mobile Phone" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Mobil6.jpeg')} alt="Mobile Phone" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Arrival7.webp')} alt="Top Offers" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                             <div>
//                                 <Card className="image-box card">
//                                     <img className="center"
//                                         src={require('../../../src/assets/images/Arrival8.webp')} alt="Top Offers" />

//                                     <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $120</p>

//                                     </Card.Body>
//                                 </Card>
//                             </div>
//                         </Slider>

//                     </Row>
//                 </Container>

//             </Fragment>
//         )
//     }
// }

