import React from 'react'
import { Link } from "react-router-dom";

const CoinsAll = ({ data }) => {
  return (
    <div className="table-responsive">
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Logo</th>
          <th scope="col">Symbol</th>
          <th scope="col">Price</th>
          <th scope="col">24h %</th>
          <th scope="col">Volume</th>
          <th scope="col">Market Cap</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {data.map((coin) => (
          <tr key={coin.market_cap_rank}>
            <th scope="row">{coin.market_cap_rank}</th>
            <td>
            <Link to={`/coin/${coin.id}`}><img src={coin.image} alt="logo" className="logo-symbol" /></Link>
            </td>
            <td><h6 className="mt-2"><b><Link to={`/coin/${coin.id}`}>{coin.symbol}</Link></b></h6></td>
            <td><h6 className="mt-2"><b>{coin.current_price.toLocaleString()}$</b></h6></td>
            <td><h6 className="mt-2"><b>{coin.price_change_percentage_24h.toFixed(2)}%</b></h6></td>
            <td><h6 className="mt-2"><b>{coin.total_volume.toLocaleString()}</b></h6></td>
            <td><h6 className="mt-2"><b>{coin.market_cap.toLocaleString()}$</b></h6></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
    )
}

export default CoinsAll