import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import AppURL from '../../api/AppURL';
import axios from 'axios'
import { Link } from 'react-router-dom';
import CategoryLoading from '../PlaceHolder/CategoryLoading';


class Categories extends Component {
    //     render() {
    //         return (
    //             <Fragment>
    //                 <Container className="text-center" fluid={true}>
    //                     <div className="section-title text-center mb-55"><h2> CATEGORIES</h2>
    //                         <p>Some Of Our Exclusive Collection, You May Like</p>
    //                     </div>
    //                     <Row>
    //                         <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12}>
    //                             <Row>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories1.webp')} alt="Top Offers" />
    //                                             <h5 className="category-name">Top Offers</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories2.webp')} alt="Grocery" />
    //                                             <h5 className="category-name">Grocery</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories3.webp')} alt="Mobile " />
    //                                             <h5 className="category-name">Mobiles</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories4.webp')} alt="Fashion" />
    //                                             <h5 className="category-name">Fashion</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                             </Row>
    //                         </Col>
    //                         <Col key={1} xl={6} lg={6} md={6} sm={6} xs={12}>
    //                             <Row>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories5.webp')} alt="Electronics" />
    //                                             <h5 className="category-name">Electronics</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories6.webp')} alt="Home" />
    //                                             <h5 className="category-name">Home</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories7.webp')} alt="Appliances" />
    //                                             <h5 className="category-name">Appliances</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                                 <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
    //                                     <Card className="h-100 w-100 text-center">
    //                                         <Card.Body>
    //                                             <img className="center"
    //                                                 src={require('../../../src/assets/images/Categories8.webp')} alt="Beauty" />
    //                                             <h5 className="category-name">Beauty</h5>
    //                                         </Card.Body>
    //                                     </Card>
    //                                 </Col>
    //                             </Row>
    //                         </Col>
    //                     </Row>
    //                 </Container>
    //             </Fragment>
    //         )
    //     }
    // }


    constructor() {
        super();
        this.state = {
            MenuData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.get(AppURL.AllCategoryDetails).then(response => {
            this.setState({ MenuData: response.data });
            this.setState({
                MenuData: response.data, isLoading: "d-none",
                mainDiv: " "
            });

        }).catch(error => {
        });
    }
    render() {
        const CatList = this.state.MenuData;
        const MyView = CatList.map((CatList, i) => {
            return <Col key={i.toString()} className="p-0 " xl={1} lg={2} md={3} sm={4} xs={4}>
                <Link to={"/productcategory/"+CatList.category_name} style={{textDecoration: 'none'}} >

                    <Card className="h-100 w-100 text-center">
                        <Card.Body>
                            <img className="center" src={CatList.category_image} alt="{category_name}" />
                            <h4 className="category-name text-center">{CatList.category_name}</h4>
                        </Card.Body>
                    </Card>
                </Link>
            </Col >
        })
        return (
            <Fragment>
                <CategoryLoading isLoading={this.state.isLoading} />

                <div className={this.state.mainDiv}>

                    <Container className="text-center" fluid={true}>
                        <div className="section-title text-center mb-1 mt-2"><h2>CATEGORIES</h2>
                            <p>Some Of Our Exclusive Category, You May Like</p>
                        </div>
                        <Row className=" m-1">
                            {MyView}
                        </Row>
                    </Container>
                </div>

            </Fragment >
        )
    }
}
export default Categories