import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HomeSlider extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    const SliderData = this.props.data;
    const MyView = SliderData.map((SliderList, i) => {
      return <div key={i.toString()}>
        <img className="slider-img" src={SliderList.slider_image} alt='SliderList'/>
      </div>
    })





    return (
      <div>
        <Slider {...settings}>
          {/* <div>
            <img className="slider-img"
              src={require('../../../src/assets/images/slider1.jpeg')} alt="image1" />
          </div>
          <div>
            <img className="slider-img"
              src={require('../../../src/assets/images/slider2.jpeg')} alt="image1" />
          </div>
          <div>
            <img className="slider-img"
              src={require('../../../src/assets/images/slider3.jpeg')} alt="image1" />
          </div>
          <div>
            <img className="slider-img"
              src={require('../../../src/assets/images/pexels2.jpg')} alt="image2" />
          </div> */}

          {MyView}

        </Slider>



      </div>
    )
  }
}

export default HomeSlider

