import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import AppURL from '../../api/AppURL';
import axios from 'axios'
import FeaturedLoading from '../PlaceHolder/FeaturedLoading';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
class FeaturedProducts extends Component {

     constructor(props) {
          super(props);
          this.next = this.next.bind(this);
          this.previous = this.previous.bind(this)
          this.state = {
               ProductData: [],
               isLoading: "",
               mainDiv: "d-none"
          }
     }
     next() {
          this.slider.slickNext();
     }
     previous() {
          this.slider.slickPrev();
     }
     componentDidMount() {
          axios.get(AppURL.ProductListByRemark("FEATURED")).then(response => {
               this.setState({ ProductData: response.data });
               this.setState({
                    ProductData: response.data, isLoading: "d-none",
                    mainDiv: " "
               });
          }).catch(error => {
          });
     }
     render() {
          var settings = {
               dots: false,
               infinite: true,
               speed: 400,
               autoplay: true,
               autoplaySpeed: 4000,
               slidesToShow: 6,
               slidesToScroll: 1,
               initialSlide: 0,
               arrows: false,
               responsive: [
                    {
                         breakpoint: 1024,
                         settings: {
                              slidesToShow: 5,
                              slidesToScroll: 1,
                              infinite: true,
                              dots: true
                         }
                    },
                    {
                         breakpoint: 900,
                         settings: {
                              slidesToShow: 3,
                              slidesToScroll: 1,
                              initialSlide: 1
                         }
                    },
                    {
                         breakpoint: 600,
                         settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1,
                              initialSlide: 1
                         }
                    },
                    {
                         breakpoint: 480,
                         settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1
                         }
                    }
               ]
          };
          const FeaturedList = this.state.ProductData;
          const MyView = FeaturedList.map((FeaturedList, i) => {
               let Delivery;
               if (FeaturedList.delivery === "free" || FeaturedList.delivery === "Free" || FeaturedList.delivery.delivery === "Yes") {
                    Delivery = <p className="product-delivery-on-card">Free Delivery</p>
               }
               if (FeaturedList.special_price === "" || FeaturedList.special_price === null) {
                    return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                         <Link to={"/productdetails/" + FeaturedList.id} style={{ textDecoration: 'none' }}>
                              <Card className="image-box card">
                                   <img className="center" src={FeaturedList.image} alt="FeaturedList" />
                                   <Card.Body >                                        <p className="product-name-on-card">{FeaturedList.title}</p>
                                        <p className="product-price-on-card">Price : ${FeaturedList.price}</p>
                                        {Delivery}
                                   </Card.Body>
                              </Card>
                         </Link>
                    </Col>
               }
               else {
                    return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                         <Link to={"/productdetails/" + FeaturedList.id} style={{ textDecoration: 'none' }}>
                              <Card className="image-box card">
                                   <img className="center" src={FeaturedList.image} alt="FeaturedList" />
                                   <Card.Body>                                        <p className="product-name-on-card">{FeaturedList.title}</p>
                                        <p className="product-price-on-card">Price : <strike className="text-secondary">${FeaturedList.price}</strike>&nbsp; ${FeaturedList.special_price}</p>
                                        {Delivery}
                                   </Card.Body>
                              </Card>
                         </Link>
                    </Col>
               }
          });
          return (
               <Fragment>
                    <FeaturedLoading isLoading={this.state.isLoading} />
                    <div className={this.state.mainDiv}>
                         <Container className="text-center" fluid={true}>
                              <div className="section-title text-center mb-1"><h2>FEATURED PRODUCT&nbsp;
                                   <a className="btn btn-sm ml-2 site-btn" onClick={this.previous} href={() => false}><FontAwesomeIcon icon={faAngleLeft} size="lg" /></a>
                                   &nbsp;
                                   <a className="btn btn-sm ml-2 site-btn" onClick={this.next} href={() => false}><FontAwesomeIcon icon={faAngleRight} size="lg" /></a>
                                   &nbsp;
                              </h2>
                                   <p>Some Of Our Exclusive Featured Products, You May Like</p>
                              </div>
                              <Row>
                                   <Slider ref={c => (this.slider = c)} {...settings}>
                                        {MyView}
                                   </Slider>
                              </Row>
                         </Container>
                    </div>
               </Fragment >
          )
     }
}
// return (
//      <Fragment>
//           <Container className="text-center" fluid={true}>
//                <div className="section-title text-center mb-55"><h2>FEATURED PRODUCT</h2>
//                     <p>Some Of Our Exclusive Collection, You May Like</p>
//                </div>

//                <Row>
//                     <Col className="p-1" key={1} xl={2} lg={3} md={4} sm={6} xs={6}>
//                          <Link to="/ProductDetails">
//                               <Card className="image-box card">
//                                    <img className="center"
//                                         src={require('../../../src/assets/images/Mobil1.jpeg')} alt="Mobile Phone" />
//                                    <Card.Body>
//                                         <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                         <p className="product-price-on-card">Price : $999</p>

//                                    </Card.Body>
//                               </Card>
//                          </Link>
//                     </Col>

//                     <Col className="p-1" key={1} xl={2} lg={3} md={4} sm={6} xs={6}>
//                          <Card className="image-box card">
//                               <img className="center"
//                                    src={require('../../../src/assets/images/Mobil2.jpeg')} alt="Mobile Phone" />
//                               <Card.Body>
//                                    <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                    <p className="product-price-on-card">Price : $999</p>

//                               </Card.Body>
//                          </Card>
//                     </Col>
//                     <Col className="p-1" key={1} xl={2} lg={3} md={4} sm={6} xs={6}>
//                          <Card className="image-box card">
//                               <img className="center"
//                                    src={require('../../../src/assets/images/Mobil3.jpeg')} alt="Mobile Phone" />
//                               <Card.Body>
//                                    <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                    <p className="product-price-on-card">Price : $999</p>

//                               </Card.Body>
//                          </Card>
//                     </Col>
//                     <Col className="p-1" key={1} xl={2} lg={3} md={4} sm={6} xs={6}>
//                          <Card className="image-box card">
//                               <img className="center"
//                                    src={require('../../../src/assets/images/Mobil4.png')} alt="Mobile Phone" />
//                               <Card.Body>
//                                    <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                    <p className="product-price-on-card">Price : $999</p>

//                               </Card.Body>
//                          </Card>
//                     </Col>
//                     <Col className="p-1" key={1} xl={2} lg={3} md={4} sm={6} xs={6}>
//                          <Card className="image-box card">
//                               <img className="center"
//                                    src={require('../../../src/assets/images/Mobil5.jpeg')} alt="Mobile Phone" />
//                               <Card.Body>
//                                    <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                    <p className="product-price-on-card">Price : $999</p>

//                               </Card.Body>
//                          </Card>
//                     </Col>

//                     <Col className="p-1" key={1} xl={2} lg={3} md={4} sm={6} xs={6}>
//                          <Card className="image-box card">
//                               <img className="center"
//                                    src={require('../../../src/assets/images/Mobil6.jpeg')} alt="Mobile Phone" />
//                               <Card.Body>
//                                    <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                    <p className="product-price-on-card">Price : $999</p>

//                               </Card.Body>
//                          </Card>
//                     </Col>

//                </Row>
//           </Container>

//      </Fragment>
// )
//      }
// }

export default FeaturedProducts
