import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/custom.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/css/placeholder-loading.min.css'
import axios from 'axios' 
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';



axios.defaults.headers.common['Authorization']='Bearer '+localStorage.getItem('token')



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   // <React.StrictMode>
    <App />
   //</React.StrictMode> }
);

if ('serviceWorker' in navigator) {
   window.addEventListener('load', function() {
     navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
       console.log('Service worker registered:', registration);
     }).catch(function(error) {
       console.log('Service worker registration failed:', error);
     });
   });
 }

reportWebVitals();

serviceWorkerRegistration.register();
