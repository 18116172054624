import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class Category extends Component {
    render() {
        const MyList = this.props.ProductData;
        const Category = this.props.Category;
        const MyView = MyList.map((ProductList, i) => {
            let Delivery;
            if (ProductList.delivery === "free" || ProductList.delivery === "Free") {
                Delivery = <p className="product-delivery-on-card">Free Delivery</p>
            }
            if (ProductList.special_price === "" || ProductList.special_price === null) {
                return <Col className="p-0" xl={2} lg={2} md={3} sm={4} xs={4}>
                    <Link to={"/productdetails/" + ProductList.id} style={{textDecoration: 'none'}}>
                        <Card className="NewImage-box card w-100">
                            <img className="center w-75" src={ProductList.image} alt="ProductList" />
                            <Card.Body>
                                <p className="product-name-on-card">{ProductList.title}</p>
                                <p className="product-price-on-card">Price : ${ProductList.price}</p>
                                {Delivery}
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
            else {
                return <Col className="p-0" xl={2} lg={3} md={3} sm={4} xs={4}>
                    <Link to={"/productdetails/" + ProductList.id} style={{textDecoration: 'none'}}>
                        <Card className="NewImage-box card w-100">
                            <img className="center w-75" src={ProductList.image} alt="ProductList" />
                            <Card.Body>
                                <p className="product-name-on-card">{ProductList.title}</p>
                                <p className="product-price-on-card">Price : <strike className="text-secondary">${ProductList.price}</strike> ${ProductList.special_price}</p>
                                {Delivery}
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
        });
        return (
            <Fragment>
                <Container className="text-center " fluid={true}>
                    <div className="section-title text-center mb-55"><h2 className='mt-4'> {Category}</h2>
                        <h4> Some Of Our {Category} Products, You May Find Them Interesting</h4>
                    </div>
                    <Row>
                        {MyView}
                    </Row>
                </Container>

            </Fragment>
        )
    }
}

export default Category
