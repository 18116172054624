import React, { Component, Fragment } from 'react'
import { Navbar, Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/images/coverloop2.webp';
import Bars from '../../assets/images/Bars.png';
import { Link, Redirect } from "react-router-dom";
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import MegaMenuMobile from '../home/MegaMenuMobile';


class NavMenuMobile extends Component {

  constructor() {
    super();
    this.state = {
      SideNavState: "sideNavClose",
      ContentOverState: "ContentOverlayClose",
      Searchkey: "",
      SearchRedirectStauts: false,
      cartCount: 0,

    }
    this.SearchOnChange = this.SearchOnChange.bind(this);
    this.SeachOnClick = this.SeachOnClick.bind(this);
    this.searchRedirect = this.searchRedirect.bind(this);
  }
  componentDidMount() {
    let email = this.props.email;
    axios.get(AppURL.CartCount(email)).then((response) => {
      this.setState({ cartCount: response.data })

    }).catch(error => {

    });
  }

  logout = () => {
    localStorage.clear();
  }

  SearchOnChange(event) {
    let Searchkey = event.target.value;
    // alert(Searchkey);
    this.setState({ Searchkey: Searchkey });
  }

  SeachOnClick() {
    if (this.state.Searchkey.length >= 2) {
      this.setState({ SearchRedirectStauts: true })
    }
  }
  handleKeypress = (event) => {
    if (event.key === 'Enter') {
      this.SeachOnClick();
    }
  }


  searchRedirect() {
    if (this.state.SearchRedirectStauts === true) {
      return <Redirect to={"/productbysearch/" + this.state.Searchkey} />
    }
  }

  MenuBarClickHandler = () => {
    this.SideNavOpenClose();
  }


  ContentOverlayClickHandler = () => {
    this.SideNavOpenClose();
  }


  SideNavOpenClose = () => {
    let SideNavState = this.state.SideNavState;
    if (SideNavState === "sideNavOpen") {
      this.setState({ SideNavState: "sideNavClose", ContentOverState: "ContentOverlayClose" })

    }
    else {
      this.setState({ SideNavState: "sideNavOpen", ContentOverState: "ContentOverlayOpen" })
    }
  }

  render() {
    let buttons;
    if (localStorage.getItem('token')) {
      buttons = (
        <div>
          <Link to="/Favourite" className="btn" href={() => false}><FontAwesomeIcon icon={faHeart} size="lg" /><sup><span className="badge text-white bg-danger">3</span></sup> </Link>

          <Link to="/Notification" className="btn" href={() => false}><FontAwesomeIcon icon={faBell} size="lg" /><sup><span className="badge text-white bg-danger">5</span></sup> </Link>
          <Link to="/CryptoTracking" className="cart-btn">Crypto</Link>

          <Link to="/profile"  className="cart-btn ms-1">PROFILE</Link>
          <Link to="/" onClick={this.logout}  className="cart-btn ms-1">LOGOUT</Link>

          <Link to="/cart" className="cart-btn ms-1"><FontAwesomeIcon icon={faCartShopping} size="lg" /> {this.state.cartCount} Items </Link>
        </div>
      )

    } else {
      buttons = (
        <div  className=" me-0">
          <Link to="/Favourite" className="btn" href={() => false}><FontAwesomeIcon icon={faHeart} size="lg" /><sup><span className="badge text-white bg-danger">3</span></sup> </Link>

          <Link to="/Notification" className="btn ms-0" href={() => false}><FontAwesomeIcon icon={faBell} size="lg" /><sup><span className="badge text-white bg-danger">5</span></sup> </Link>

          {/* <Link to="/register" className="h4 btn">REGISTER</Link> */}

          <Link to="/CryptoTracking" className="cart-btn">Crypto</Link>

          {/* <Link to="/Cart" className="cart-btn ms-1"><FontAwesomeIcon icon={faCartShopping} size="lg" /> 0 Items </Link> */}
          <Link to="/login" className="cart-btn ms-1">LOGIN</Link>

        </div>
      )

    }
    return (
      <Fragment>
        <div className="TopSectionDown ">
          <Navbar className="navbar" bg="light">
            <Container fluid={"true"} className=" fixed-top shadow-sm p-2 mb-0 bg-white">
              <Row className="mb-4">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <label onClick={this.MenuBarClickHandler} className="notification-card" ><img className="bar-img" src={Bars} alt="bars" /> All&nbsp;</label>
                  <Link to="/"><img className="nav-logo" src={Logo} alt="logo" /></Link>
                  {/* <Link to="/Cart" className="cart-btn ms-4"><FontAwesomeIcon icon={faCartShopping} size="lg" /> 0 Items </Link> */}
                </Col>
                <Col className="p-0 mt-0 mb-0" lg={4} md={4} sm={12} xs={12}>
                  <div className="input-group w-100">
                   
                    <input onChange={this.SearchOnChange} onKeyPress={this.handleKeypress} type="text" className="form-control" />
                    <Button onClick={this.SeachOnClick} type="button" className="btn site-btn"><FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                    </Button>
                  </div>
                </Col>
                <Col className="p-1 mt-0" lg={4} md={4} sm={12} xs={12}>
                  {buttons}
                </Col>
              </Row>
              {this.searchRedirect()}

            </Container>
          </Navbar>

          <div className={this.state.SideNavState}>
            <MegaMenuMobile />

          </div>
          <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>

          </div>

        </div>
      </Fragment>
    )
  }
}


export default NavMenuMobile
