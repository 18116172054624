import React, { Component } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios'
import { Link } from 'react-router-dom';

class MegaMenuMobile extends Component {
    constructor() {
        super();
        this.btnToggleRef = React.createRef();

        this.state = {
          MenuData: []
        }
      }
      componentDidMount() {
        axios.get(AppURL.AllCategoryDetails).then(response => {
          this.setState({ MenuData: response.data });
          this.props.history.replace("/home", "urlhistory");
    
        }).catch(error => {
        });
      }
      toggleMenu = () => {
   
        this.btnToggleRef.current.click();
      
    };
      handler = (e) => {
    
        e.target.classList.toggle('active');
        var panel = e.target.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
    
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
    
      };

      render() {
        const CatList = this.state.MenuData;
        const MyView = CatList.map((CatList, i) => {
          return <div key={i.toString()}>
            <button onClick={this.handler} className="accordionAll">
              <img className="accordionMenuIconAll" src={CatList.category_image} alt="product" />&nbsp; {CatList.category_name}
            </button>
            <div className="panelAll">
              <ul>
    
                {
                  (CatList.subcategory_name).map((SubList, i) => {
                    return <li > <Link to={"/productsubcategory/" + CatList.category_name + "/" + SubList.subcategory_name} className="accordionItem" ><h6 className='mt-2'>{SubList.subcategory_name}</h6></Link></li>
                  })
                }
              
    
              </ul>
            </div>
    
          </div>
        });
    
    
        return (
          <div className="accordionMenuDivAll">
            <div className="accordionMenuDivInsideAll">
    
              {MyView}
    
            </div>
    
          </div>
        )
      }
    }
    

    
//     handler = (e) => {

//         e.target.classList.toggle('active');
//         var panel = e.target.nextElementSibling;
//         if (panel.style.maxHeight) {
//             e.target.nextElementSibling.style.maxHeight = null;

//         } else {
//             e.target.nextElementSibling.style.maxHeight = e.target.nextElementSibling.scrollHeight + 'px';
//         }
//     };


//     render() {
//         return (
//             <div className='accordionMenuDivMobile'>
//                 <div className='accordionMenuDivInsideMobile'>
//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>

//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>

//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>


//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>

//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>


//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>


//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>



//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>


//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>

//                     <button className='accordion' onClick={this.handler} >

//                         <img className='accordionMenuIconMobile'
//                             src={require('../../../src/assets/images/MenuIcon.png')} alt="MobileMenu" />&nbsp;&nbsp; Man's Clothing
//                     </button>
//                     <div className='panelMobile'>
//                         <ul>

//                             <li><a className="accordionItemMobile" href={() => false}> Mans Tshirt 1</a></li>
//                             <li><a className="accordionItemMobile" href={() => false} > Mans Tshirt 2</a></li>

//                         </ul>
//                     </div>

//                 </div>
//             </div>
//         )
//     }
// }

export default MegaMenuMobile
