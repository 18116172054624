import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class MegaMenu extends Component {
  // constructor() {
  //   super();

  // }


  //   constructor(){
  //     super();
  //     this.MegaMenu = this.MegaMenu.bind(this);
  // }

  // componentDidMount(){
  //     this.MegaMenu();
  // }



  // MegaMenu(){
  //     var acc = document.getElementsByClassName("accordion");
  //     var accNum = acc.length;
  //     alert(accNum)
  //    }


  //  handler = document.querySelectorAll(".accordion").addEventListener("click", function(e) {
  //   if (e.target.nextElementSibling.style.maxHeight) {
  //       e.target.nextElementSibling.style.maxHeight = null;
  //   } else {
  //       e.target.nextElementSibling.style.maxHeight = e.target.nextElementSibling.scrollHeight + "px";
  //   }
  // });


  handler = (e) => {

    e.target.classList.toggle('active');
    var panel = e.target.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;

    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  };


  render() {
    //     return (


    //       <div className='accordionMenuDiv'>
    //         <div className='accordionMenuDivInside'>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp; Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" /> &nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" /> &nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //           <button className='accordion' onClick= {this.handler} >

    //             <img className='accordionMenuIcon'
    //               src={require('../../../src/assets/images/MenuIcon.png')} alt="Menu" />&nbsp;&nbsp;Man's Clothing
    //           </button>
    //           <div className='panel'>
    //             <ul>

    //               <li><a className="accordionItem" href={() => false}> Mans Tshirt 1</a></li>
    //               <li><a className="accordionItem" href={() => false} > Mans Tshirt 2</a></li>

    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     )
    //   }
    // }

    const CatList = this.props.data;
    const MyView = CatList.map((CatList, i) => {
      return <div key={i.toString()}>
        <button className="accordion" onClick={this.handler}>
          <img className="accordionMenuIcon" src={CatList.category_image} alt="Menu"  />&nbsp; {CatList.category_name}
        </button>
        <div className="panel">
          <ul>
            {
              (CatList.subcategory_name).map((SubList, i) => {
                return <li><Link to={"productsubcategory/"+CatList.category_name+"/"+SubList.subcategory_name } className="accordionItem" ><h6 className='mt-2'>{SubList.subcategory_name}</h6></Link></li>
              })
            }
          </ul>
        </div>
      </div>
    });

    
    return (
      <div className="accordionMenuDiv">
        <div className="accordionMenuDivInside">

          {MyView}

        </div>
      </div>
    )
  }
}

export default MegaMenu
