import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'
import axios from 'axios';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppURL from '../../api/AppURL';



class FooterMobile extends Component {
  constructor() {
    super();
    this.state = {
      address: "",
      android_app_link: "",
      ios_app_link: "",
      facbook_link: "",
      twitter_link: "",
      linkedin_link: "",
      instagram_link: "",
      copyright_text: "",
      loaderDiv: "",
      mainDiv: "d-none"
    }
  }


  componentDidMount() {
    axios.get(AppURL.AllSiteInfo).then(response => {
      let StatusCode = response.status;
      if (StatusCode === 200) {
        let JsonData = (response.data)[0];
        this.setState({
          address: JsonData['address'],
          android_app_link: JsonData['android_app_link'],
          ios_app_link: JsonData['ios_app_link'],
          facbook_link: JsonData['facbook_link'],
          twitter_link: JsonData['twitter_link'],
          linkedin_link: JsonData['linkedin_link'],
          instagram_link: JsonData['instagram_link'],
          copyright_text: JsonData['copyright_text'],
          loaderDiv: "d-none",
          mainDiv: ""
        });
      } else {

        toast.error("Somthing Went Wrong", {
          position: "bottom-center"
        });

      }

    }).catch(error => {

    });
  }

  render() {
    return (

      <Fragment>
        <div className="footerback  m-0 mt-0 pt-3 pb-1  shadow-sm">
          <Container className="text-center">
            <Row className=" mt-2  px-0 my-5" >
              <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
                <h5 className="footer-menu-title">MAILING ADDRESS</h5>
                <h6>{parse(this.state.address)} </h6>
                <Row>

                  <Col className=" text-center ms-auto" lg={3} md={3} sm={5} xs={5}>
                    <Link to="/about" className="footer-link"><h5 className="footer-menu-title"> About Us</h5></Link>
                    <Link to="/Contact" className="footer-link"><h5 className="footer-menu-title">Contact Us</h5></Link>
                  </Col>
                  <Col className=" text-center me-auto" lg={3} md={3} sm={5} xs={5}>
                    <Link to="/Purchase" className="footer-link"><h5 className="footer-menu-title">How To Purchase</h5></Link>
                    <Link to="/RefundPolicy" className="footer-link"><h5 className="footer-menu-title"> Refund Policy </h5></Link><br></br>
                  </Col>
                </Row>
                <h5 className="footer-menu-title">SOCIAL LINK</h5>
                <a href={this.state.facbook_link} ><FontAwesomeIcon icon={faFacebook} size="2x" /></a> &nbsp; &nbsp;
                <a href={this.state.instagram_link}><FontAwesomeIcon icon={faInstagram} size="2x" /></a> &nbsp; &nbsp;
                <a href={this.state.twitter_link}><FontAwesomeIcon icon={faTwitter} size="2x" /></a> &nbsp; &nbsp;
                <a href={this.state.linkedin_link}><FontAwesomeIcon icon={faLinkedin} size="2x" /></a>

              </Col>
              <Col className=" text-center p-2" lg={3} md={3} sm={6} xs={12}>
                <h5 className="footer-menu-title">DOWNLOAD APPS</h5>
                <a href={this.state.ios_app_link}><img src={require('../../../src/assets/images/apple.png')} alt="apple" /></a><br />
                <a href={this.state.android_app_link}><img className="mt-2" src={require('../../../src/assets/images/google.png')} alt="google" /></a>
              </Col>
            </Row>
          </Container>
          <Container fluid={true} className=" text-center m-0 pt-3  pb-0 bg-dark">
            <Row>
              <h6 className="text-white">{parse(this.state.copyright_text)}</h6>
            </Row>
          </Container>
        </div>

      </Fragment>
    )
  }
}

export default FooterMobile
