import React, { Component, Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import FeaturedProducts from '../components/home/FeaturedProducts'
import Collection from '../components/home/Collection'
import Categories from '../components/home/Categories'
import NewArrival from '../components/home/NewArrival'
import HomeTop from '../components/home/HomeTop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import HomeTopMobile from '../components/home/HomeTopMobile'
import FooterDesktop  from '../components/common/FooterDesktop'
import FooterMobile  from '../components/common/FooterMobile'
import AppURL from '../api/AppURL'
import axios from 'axios'



class HomePage extends Component {
  componentDidMount(){
    window.scroll(0,0);
    this.setState({ ruinPerformance: true });
    this.GetVisitorDetails();

}
GetVisitorDetails =()=>{
  axios.get(AppURL.VisitorDetails).then().catch()
}

  render() {
    return (
      <Fragment>
      <div className="Desktop">
          <NavMenuDesktop />
          <HomeTop />
      </div>
      <div className="Mobile">
          <NavMenuMobile />
          <HomeTopMobile />
      </div>
      <Categories />
      <FeaturedProducts />
      <NewArrival />
      <Collection />
      <div className="Desktop">
          <FooterDesktop />
      </div>

      <div className="Mobile">
          <FooterMobile />
      </div>

  </Fragment>
    )
  }
}

export default HomePage
