import React, { useState } from "react";
import { useEffect } from "react";
import Spinner from "./Spinner";
import axios from 'axios'
import { baseUrl } from '../../api/CoinUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CoinsAll from "./CoinsAll";

const CryptoTracking = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    axios(baseUrl)
      .then((response) => {
        setLoading(false);
        // console.log(response.data);
        setData(response.data);

      })
      .catch((error) => {
        setError("An error occured");
      });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="mt-5"><b>Cryptocurrency Prices by Market Cap</b></h3>
          {/* <h6>A place to manage all of your trade informations and actions</h6>
          <h6>Do not forget to always using asset and risk management!!!!!</h6> */}
        </div>
      </div>

      <div className="row">
        <div className="col-12">{loading ? <Spinner /> : error !== "" ? <h1>{error}</h1> : <CoinsAll data={data} />}</div>
      </div>

      {/* <Spinner /> */}
    </div>
  );
};

export default CryptoTracking;
