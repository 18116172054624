import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

class SubCategory extends Component {

    render() {
        const MyList = this.props.ProductData;
        const Category = this.props.Category;
        const SubCategory = this.props.SubCategory;
        const MyView = MyList.map((ProductList, i) => {
            let Delivery;
            if (ProductList.delivery === "free" || ProductList.delivery === "Free") {
                Delivery = <p className="product-delivery-on-card">Free Delivery</p>
            }
            if (ProductList.special_price === "" || ProductList.special_price === "nall") {
                return <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
                    <Link to={"/productdetails/" + ProductList.id} style={{ textDecoration: 'none' }} >

                        <Card className="2 card w-100">
                            <img className="center w-75" src={ProductList.image} alt="ProductList" />
                            <Card.Body>
                                <p className="product-name-on-card">{ProductList.title}</p>
                                <p className="product-price-on-card">Price : ${ProductList.price}</p>
                                {Delivery}
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
            else {
                return <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
                    <Link to={"/productdetails/" + ProductList.id} style={{ textDecoration: 'none' }}>

                        <Card className=" card w-100">
                            <img className="center w-75" src={ProductList.image} alt="ProductList" />
                            <Card.Body>
                                <p className="product-name-on-card">{ProductList.title}</p>
                                <p className="product-price-on-card">Price : <strike className="text-secondary">${ProductList.price}</strike> ${ProductList.special_price}</p>
                                {Delivery}
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
        });
        return (
            <Fragment>
                <Container className="text-center pt-4" fluid={true}>
                    <div className="breadbody">
                        <Breadcrumb>
                            <Breadcrumb.Item> <Link to="/" className="breadbody-link"  > Home </Link> </Breadcrumb.Item>

                            <Breadcrumb.Item> <Link to={"/productcategory/" + Category}> {Category} </Link> </Breadcrumb.Item>

                            {/* <Breadcrumb.Item> <Link to={"/productsubcategory/" + Category + "/" + SubCategory}> {SubCategory} </Link> </Breadcrumb.Item> */}

                        </Breadcrumb>
                    </div>
                    <div className="section-title text-center mb-40 mt-2"><h2> {Category } /  {SubCategory}  </h2>

                        <h4> Some Of Our {SubCategory} Products, You May Find Them Interesting</h4>
                    </div>
                    <Row>
                        {MyView}
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default SubCategory
