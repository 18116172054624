import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppURL from '../../api/AppURL';
import axios from 'axios'
import CollectionLoading from '../PlaceHolder/CollectionLoading';


class Collection extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this)
        this.state = {
            ProductData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        axios.get(AppURL.ProductListByRemark("COLLECTION")).then(response => {

            this.setState({ ProductData: response.data });
            this.setState({
                ProductData: response.data, isLoading: "d-none",
                mainDiv: " "
            });

        }).catch(error => {

        });
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 400,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const CollectionList = this.state.ProductData;
        const MyView = CollectionList.map((CollectionList, i) => {
            let Delivery;
            if (CollectionList.delivery === "free" || CollectionList.delivery === "Free"|| CollectionList.delivery === "Yes") {
                Delivery = <p className="product-delivery-on-card">Free Delivery</p>
            }
            if (CollectionList.special_price === "" || CollectionList.special_price === null) {
                return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                    <Link to={"/productdetails/"+CollectionList.id} style={{ textDecoration: 'none' }}>
                        <Card className="NewImage-box text-center" >
                            <img className="center-collection " src={CollectionList.image} alt="CollectionList" />
                            <Card.Body>
                                <p className="product-name-on-card">{CollectionList.title}</p>
                                <p className="product-price-on-card">Price : ${CollectionList.price}</p>
                                {Delivery}

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
            else {

                return <Col className="p-0 m-0 " key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                    <Link to={"/productdetails/"+CollectionList.id} style={{ textDecoration: 'none' }}>
                        <Card className="NewImage-box text-center   ">
                            <img className="center-collection " src={CollectionList.image} alt="FeaturedList" />
                            <Card.Body >
                                <p className="product-name-on-card">{CollectionList.title}</p>
                                <p className="product-price-on-card">Price : <strike className="text-secondary">${CollectionList.price}</strike>&nbsp; ${CollectionList.special_price}</p>
                                {Delivery}

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
        });
        return (
            <Fragment>
                <CollectionLoading isLoading={this.state.isLoading} />

                <div className={this.state.mainDiv}>
                    <Container className="text-center" fluid={true}>
                        <div className="section-title text-center mb-55"><h2> PRODUCT COLLECTION&nbsp;
                            <a className="btn btn-sm ml-2 site-btn" onClick={this.previous} href={() => false}><FontAwesomeIcon icon={faAngleLeft} size="lg" /></a>
                            &nbsp;
                            <a className="btn btn-sm ml-2 site-btn" onClick={this.next} href={() => false}><FontAwesomeIcon icon={faAngleRight} size="lg" /></a>
                            &nbsp;
                        </h2>
                            <p>Some Of Our Exclusive Collection, You May Like</p>
                        </div>
                        <Row>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {MyView}
                            </Slider>
                        </Row>
                    </Container>
                </div>
            </Fragment >
        )
    }
}










//     render() {
//         return (
//             <Fragment>
//                 <Container className="text-center" fluid={true}>
//                     <div className="section-title text-center mb-55"><h2> PRODUCT COLLECTION</h2>
//                         <p>Some Of Our Exclusive Collection, You May Like</p>
//                     </div>

//                     <Row>
//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/olay.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Olay</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>


//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection2.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Striped Men Round Neck Maroon, Grey</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection3.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Color Block Men Round Neck Grey</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection4.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>

//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection5.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>



//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection6.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>



//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection7.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>



//                         <Col className="p-0" xl={2} lg={2} md={3} sm={6} xs={6}>
//                             <Card className="image-box card w-100">
//                                 <img className="center w-75"
//                                     src={require('../../../src/assets/images/Collection8.webp')} alt="Top Offers" />
//                                 <Card.Body>
//                                     <p className="product-name-on-card">Printed Men Hooded Neck Red T-Shirt</p>
//                                     <p className="product-price-on-card">Price : $120</p>

//                                 </Card.Body>
//                             </Card>
//                         </Col>


//                     </Row>
//                 </Container>
//             </Fragment>
//         )
//     }
// }

export default Collection