import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import SuggestedProduct from './SuggestedProduct'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link, Redirect } from 'react-router-dom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import ReviewList from './ReviewList'
import cogoToast from 'cogo-toast';
import AppURL from '../../api/AppURL'
import axios from 'axios'

class ProductDetails extends Component {

    constructor() {
        super();
        this.state = {
            previewImg: "0",
            isSize: null,
            isColor: null,
            color: "",
            size: "",
            quantity: "",
            productCode: null,
            addToCart: "Add To Cart",
            PageRefreshStatus: false,
            addToFav: "Favourite"

        }
    }
    addToFav = () => {
        this.setState({ addToFav: "Adding..." })
        let productCode = this.state.productCode;
        let email = this.props.user.email;

        if (!localStorage.getItem('token')) {
            cogoToast.warn('Please You have to Login First', { position: 'top-right' });
            this.setState({ addToFav: "Favourite" })

        }
        else {

            axios.get(AppURL.AddFavourite(productCode, email)).then(response => {
                if (response.data === 1) {
                    cogoToast.success("Product Added to Favourite", { position: 'top-right' });
                    this.setState({ addToFav: "Favourite" })

                }
                else {
                    cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
                    this.setState({ addToFav: "Favourite" })
                }

            }).catch(error => {
                cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
                this.setState({ addToFav: "Favourite" })

            });

        }

    }  // end ADD TO FAV 

    imgOnClick = (event) => {
        let imgSrc = event.target.getAttribute('src');
        this.setState({ previewImg: imgSrc })
    }
    addToCart = () => {
        let isSize = this.state.isSize;
        let isColor = this.state.isColor;
        let color = this.state.color;
        let size = this.state.size;
        let quantity = this.state.quantity;
        let productCode = this.state.productCode;
        let email = this.props.user.email;

        if (isColor === "YES" && color.length === 0 || color === 'Choose Color') {
            cogoToast.error('Please Select Color', { position: 'top-center' });
        }
        else if (isSize === "YES" && size.length === 0 || size === 'Choose Size') {
            cogoToast.error('Please Select Size', { position: 'top-center' });
        }
        else if (quantity.length === 0 || quantity === 'Choose Quantity') {
            cogoToast.error('Please Select Quantity', { position: 'top-center' });
        }
        else if (!localStorage.getItem('token')) {
            cogoToast.error('Please You have to Login First', { position: 'top-center' });
        }
        else {
            this.setState({ addToCart: "Adding..." })
            let MyFormData = new FormData();
            MyFormData.append("color", color);
            MyFormData.append("size", size);
            MyFormData.append("quantity", quantity);
            MyFormData.append("product_code", productCode);
            MyFormData.append("email", email);
            axios.post(AppURL.addToCart, MyFormData).then(response => {
                if (response.data === 1) {
                    cogoToast.success("Product Added Successfully", { position: 'top-center' });
                    this.setState({ addToCart: "Add To Cart" })
                    this.setState({ PageRefreshStatus: true })

                }
                else {
                    cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
                    this.setState({ addToCart: "Add To Cart" })
                }

            }).catch(error => {
                cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
                this.setState({ addToCart: "Add To Cart" })

            });

        }

    }
    PageRefresh = () => {
        if (this.state.PageRefreshStatus === true) {
            let URL = window.location;
            return (
                <Redirect to={URL} />
            )
        }
    }
    colorOnChange = (event) => {
        let color = event.target.value;
        // alert(color);
        this.setState({ color: color })
    }

    sizeOnChange = (event) => {
        let size = event.target.value;
        // alert(size);
        this.setState({ size: size })
    }

    quantityOnChange = (event) => {
        let quantity = event.target.value;
        this.setState({ quantity: quantity })
    }

    PriceOption(price, special_price) {
        //if(special_price.length === 0) {
        if (special_price === "" || special_price === null) {
            return (
                <h3 className="product-price-on-card"><b> Price : {price}$ </b></h3>
            )
        } else {

            return (
                <h3 className="product-price-on-card">
                    Price : <strike className="text-secondary">${price} </strike>&nbsp; ${special_price}
                </h3>
            )

        }
    }


    //     render() { 
    //         return (
    //             <Fragment>
    //                 <Container className="BetweenTwoSection">
    //                     <Row className="p-2">
    //                         <Col className="shadow-sm bg-white pb-3 mt-4" md={12} lg={12} sm={12} xs={12}>
    //                             <Row>
    //                                 <Col className="p-3" md={6} lg={6} sm={12} xs={12}>
    //                                     <img className="w-100" src={require('../../../src/assets/images/product1.png')} alt="product1" />
    //                                     <Container className="my-3">
    //                                         <Row>
    //                                             <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
    //                                                 <img className="w-100" src={require('../../../src/assets/images/product1.png')} alt="product1" />
    //                                             </Col>
    //                                             <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
    //                                                 <img className="w-100" src={require('../../../src/assets/images/product2.png')} alt="product2" />
    //                                             </Col>
    //                                             <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
    //                                                 <img className="w-100" src={require('../../../src/assets/images/product3.png')} alt="product3" />
    //                                             </Col>
    //                                             <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
    //                                                 <img className="w-100" src={require('../../../src/assets/images/product5.png')} alt="product5" />
    //                                             </Col>
    //                                         </Row>
    //                                     </Container>
    //                                 </Col>
    //                                 <Col className="p-3 " md={6} lg={6} sm={12} xs={12}>
    //                                     <h5 className="Product-Name">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
    //                                     <h6 className="section-sub-title">Some Of Our Exclusive Collection, You May Like Some Of Our Exclusive Collectio</h6>
    //                                     <div className="input-group">
    //                                         <div className="Product-price-card d-inline ">Reguler Price 200</div>
    //                                         <div className="Product-price-card d-inline ">50% Discount</div>
    //                                         <div className="Product-price-card d-inline ">New Price 100</div>
    //                                     </div>
    //                                     <h6 className="mt-2">Choose Color</h6>
    //                                     <div className="input-group">
    //                                         <div className="form-check mx-1">
    //                                             <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
    //                                             <label className="form-check-label" htmlFor="exampleRadios1">Black</label>
    //                                         </div>
    //                                         <div className="form-check mx-1">
    //                                             <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
    //                                             <label className="form-check-label" htmlFor="exampleRadios1">Green</label>
    //                                         </div>
    //                                         <div className="form-check mx-1">
    //                                             <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
    //                                             <label className="form-check-label" htmlFor="exampleRadios1">Red</label>
    //                                         </div>
    //                                     </div>

    //                                     <h6 className="mt-2">Choose Size</h6>
    //                                     <div className="input-group">
    //                                         <div className="form-check mx-1">
    //                                             <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
    //                                             <label className="form-check-label" htmlFor="exampleRadios1">X</label>
    //                                         </div>
    //                                         <div className="form-check mx-1">
    //                                             <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
    //                                             <label className="form-check-label" htmlFor="exampleRadios1">XX</label>
    //                                         </div>
    //                                         <div className="form-check mx-1">
    //                                             <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
    //                                             <label className="form-check-label" htmlFor="exampleRadios1">XXXX</label>
    //                                         </div>
    //                                     </div>

    //                                     <h6 className="mt-2">Quantity</h6>
    //                                     <input className="form-control text-center w-50" type="number" />

    //                                     <div className="input-group mt-3">
    //                                         <Button className="btn site-btn m-1 "> <FontAwesomeIcon icon={faCartShopping} size="lg" />   Add To Cart</Button>
    //                                         <Button className="btn btn-primary m-1"> <FontAwesomeIcon icon={faCar} size="lg" /> Order Now</Button>
    //                                         <Button className="btn btn-primary m-1"> <FontAwesomeIcon icon={faHeart} size="lg" /> Favourite</Button>
    //                                     </div>
    //                                 </Col>
    //                             </Row>

    //                             <Row>
    //                                 <Col className="" md={6} lg={6} sm={12} xs={12}>
    //                                     <h6 className="mt-2">DETAILS</h6>
    //                                     <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation</p>
    //                                     <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation</p>
    //                                 </Col>

    //                                 <Col className="" md={6} lg={6} sm={12} xs={12}>
    //                                     <h6 className="mt-2">REVIEWS</h6>
    //                                     <p className=" p-0 m-0"><span className="Review-Title">Kazi Ariyan</span> <span className="text-success"><FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> </span> </p>
    //                                     <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>

    //                                     <p className=" p-0 m-0"><span className="Review-Title">Kazi Ariyan</span> <span className="text-success"><FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /><FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> </span> </p>
    //                                     <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>

    //                                     <p className=" p-0 m-0"><span className="Review-Title">Kazi Ariyan</span> <span className="text-success"><FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> <FontAwesomeIcon icon={faStar} size="lg" /> </span> </p>
    //                                     <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>

    //                                 </Col>
    //                             </Row>

    //                         </Col>
    //                     </Row>
    //                 </Container>

    //             </Fragment>
    //         )
    //     }
    // }

    //Main test

    render() {

        let ProductAllData = this.props.data;
        let title = ProductAllData['productList'][0]['title'];
        let brand = ProductAllData['productList'][0]['brand'];
        let category = ProductAllData['productList'][0]['category'];
        let subcategory = ProductAllData['productList'][0]['subcategory'];
        let image = ProductAllData['productList'][0]['image'];

        if (this.state.previewImg === "0") {
            this.setState({ previewImg: image })
        }

        let price = ProductAllData['productList'][0]['price'];
        let product_code = ProductAllData['productList'][0]['product_code'];
        let remark = ProductAllData['productList'][0]['remark'];
        let special_price = ProductAllData['productList'][0]['special_price'];
        let star = ProductAllData['productList'][0]['star'];


        let image_one = ProductAllData['productDetails'][0]['image_one'];
        let image_two = ProductAllData['productDetails'][0]['image_two'];
        let image_three = ProductAllData['productDetails'][0]['image_three'];
        let image_four = ProductAllData['productDetails'][0]['image_four'];
        let color = ProductAllData['productDetails'][0]['color'];
        let size = ProductAllData['productDetails'][0]['size'];

        let product_id = ProductAllData['productDetails'][0]['product_id'];
        let short_description = ProductAllData['productDetails'][0]['short_description'];
        let long_description = ProductAllData['productDetails'][0]['long_description'];


        var ColorDiv = "d-none"
        if ((color !== "nall") || (color !== "")) {
            let ColorArray = color.split(',');
            var ColorOption = ColorArray.map((ColorList, i) => {
                return <option value={ColorList}> {ColorList} </option>
            })
            ColorDiv = ""
        }
        else {
            ColorDiv = "d-none"
        }
        var SizeDiv = "d-none"
        if ((size !== "nall") || (size !== "")) {
            let SizeArray = size.split(',');
            var SizeOption = SizeArray.map((SizeList, i) => {
                return <option value={SizeList}> {SizeList} </option>
            })
            SizeDiv = ""
        }
        else {
            SizeDiv = "d-none"
        }


        if (this.state.isSize === null) {
            if (size !== null || size !== "") {
                this.setState({ isSize: "YES" })
            } else {
                this.setState({ isSize: "NO" })
            }
        }


        if (this.state.isColor === null) {
            if (color !== null || color !== "") {
                this.setState({ isColor: "YES" })
            } else {
                this.setState({ isColor: "NO" })
            }
        }


        if (this.state.productCode === null) {
            this.setState({ productCode: product_code })
        }
        let stock;
        if (1===1) {
            stock = (
                <h5 className="Product-InStock"><b>In Stock</b></h5>
            )

        } else {
            stock = (
                <h5 className="Product-OutOfStock"><b>Out of Stock</b></h5>
            )

        }


        return (
            <Fragment>
                <Container fluid={true} className="BetweenTwoSection pt-4">
                    <div className="breadbody">
                        <Breadcrumb>
                            <Breadcrumb.Item> <Link className="breadbody-link" to="/"> Home </Link> </Breadcrumb.Item>

                            <Breadcrumb.Item> <Link className="breadbody-link" to={"/productcategory/" + category}> {category} </Link> </Breadcrumb.Item>

                            <Breadcrumb.Item> <Link to={"/productsubcategory/" + category + "/" + subcategory}> {subcategory} </Link> </Breadcrumb.Item>

                        </Breadcrumb>
                    </div>
                    <Row className="p-2">
                        <Col className="shadow-sm bg-white pb-3 mt-4" md={12} lg={12} sm={12} xs={12}>
                            <Row>
                                <Col className="p-3" md={6} lg={6} sm={12} xs={12}>

                                    {/* <img id="previewImg"  zoomScale={1.8} zoomType={"hover"}src={image_one} /> */}
                                    <InnerImageZoom className="detailimage" zoomScale={1.6} src={this.state.previewImg} zoomSrc={this.state.previewImg} />


                                    <Container className="my-3">
                                        <Row>
                                            <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                                                <img onClick={this.imgOnClick} className="w-100 smallimage product-sm-img" src={image_one} alt="Details" />
                                            </Col>
                                            <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                                                <img onClick={this.imgOnClick} className="w-100 smallimage product-sm-img" src={image_two} alt="Details" />
                                            </Col>
                                            <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                                                <img onClick={this.imgOnClick} className="w-100 smallimage product-sm-img" src={image_three} alt="Details" />
                                            </Col>
                                            <Col className="p-0 m-0" md={3} lg={3} sm={3} xs={3}>
                                                <img onClick={this.imgOnClick} className="w-100 smallimage product-sm-img" src={image_four} alt="Details" />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col className="p-3 " md={6} lg={6} sm={12} xs={12}>
                                    {stock}
                                    <h5 className="Product-Name"><b>{title}</b></h5>
                                    <h6 className="section-sub-title"><b> {short_description}</b> </h6>

                                    {/* <div className="input-group">
                                        <div className="Product-price-card d-inline ">Reguler Price ${price}</div>
                                        <div className="Product-price-card d-inline ">50% Discount</div>
                                        <div className="Product-price-card d-inline ">New Price ${special_price}</div>
                                    </div> */}

                                    <h3><b>{this.PriceOption(price, special_price)}</b></h3>

                                    <h6 className="mt-2">Category : <b>{category}</b>  </h6>
                                    <h6 className="mt-2">Seller : <b>Fate Runner</b></h6>


                                    <h6 className="mt-2">SubCategory : <b>{subcategory}</b></h6>

                                    <h6 className="mt-2">Brand : <b>{brand}</b></h6>

                                    {/* <h6 className="mt-2">Product Code : <b>{product_code}</b></h6> */}

                                    <div className={ColorDiv}>
                                        <h6 className="mt-2"><b>Color</b></h6>
                                        <select onChange={this.colorOnChange} style={{ width: '150px' }}>
                                            <option>Choose Color</option>
                                            {ColorOption}
                                        </select>
                                    </div>

                                    <div className={SizeDiv}>
                                        <h6 className="mt-2"><b>Size</b></h6>
                                        <select onChange={this.sizeOnChange} style={{ width: '150px' }}>
                                            <option><b>Choose Size</b></option>
                                            {SizeOption}
                                        </select>
                                    </div>

                                    <div className="" >
                                        <h6 className="mt-2"><b>Quantity</b></h6>
                                        <select onChange={this.quantityOnChange} style={{ width: '150px' }}>
                                            <option>Choose Quantity</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                        </select>
                                    </div>

                                    <div className="input-group mt-3">
                                        <Button onClick={this.addToCart} className="btn site-btn m-1 "> <FontAwesomeIcon icon={faCartShopping} size="lg" /> {this.state.addToCart} </Button>
                                        <Button className="btn btn-primary m-1"> <FontAwesomeIcon icon={faCar} size="lg" /> Order Now</Button>
                                        <Button onClick={this.addToFav} className="btn btn-primary m-1"> <FontAwesomeIcon icon={faHeart} size="lg" /> {this.state.addToFav}</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="" md={6} lg={6} sm={12} xs={12}>
                                    <h6 className="mt-2"><b>DETAILS</b></h6>

                                    <p> {parse(long_description)} </p>
                                </Col>
                                <Col className="" md={6} lg={6} sm={12} xs={12}>
                                    <ReviewList code={product_code} />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <SuggestedProduct subcategory={subcategory} />
                {this.PageRefresh()}
            </Fragment>
        )
    }
}
export default ProductDetails
