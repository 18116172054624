import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import AppURL from '../../api/AppURL';
import axios from 'axios'
import cogoToast from 'cogo-toast';
import { Redirect } from 'react-router-dom';

class Cart extends Component {

    constructor() {
        super();
        this.state = {
            ProductData: [],
            isLoading: "",
            mainDiv: "d-none",
            PageRefreshStatus: false,
            PageRedirectStaus: false,
            confirmBtn: "Confirm Order",
            State: "",
            payment: "",
            name: "",
            address: ""

        }
    }
    componentDidMount() {
        axios.get(AppURL.CartList(this.props.user.email)).then(response => {

            this.setState({
                ProductData: response.data, isLoading: "d-none",
                mainDiv: " "
            });

        }).catch(error => {

        });
    }

    removeItem = (id) => {

        axios.get(AppURL.RemoveCartList(id)).then(response => {

            if (response.data === 1) {
                cogoToast.success("Cart Item Remove", { position: 'top-right' });
                this.setState({ PageRefreshStatus: true })
            } else {
                cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
            }
        }).catch(error => {
            cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });

        });

    } // End Remove Item Mehtod 

    PageRefresh = () => {
        if (this.state.PageRefreshStatus === true) {
            let URL = window.location;
            return (
                <Redirect to={URL} />
            )
        }
    }

    ItemPlus = (id, quantity, price) => {

        axios.get(AppURL.CartItemPlus(id, quantity, price)).then(response => {

            if (response.data === 1) {
                cogoToast.success("Item Quantity Increased", { position: 'top-right' });
                this.setState({ PageRefreshStatus: true })
            } else {
                cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
            }
        }).catch(error => {
            cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });

        });

    } // End ItemPlus Mehtod 


    ItemMinus = (id, quantity, price) => {

        axios.get(AppURL.CartItemMinus(id, quantity, price)).then(response => {

            if (response.data === 1) {
                cogoToast.success("Item Quantity Decreased", { position: 'top-right' });
                this.setState({ PageRefreshStatus: true })
            } else {
                cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
            }
        }).catch(error => {
            cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });

        });

    } // End ItemMinus Mehtod
    StateOnChange = (event) => {
        let State = event.target.value;
        this.setState({ State: State })
    }

    paymentMethodOnChange = (event) => {
        let payment = event.target.value;
        this.setState({ payment: payment })
    }

    nameOnChange = (event) => {
        let name = event.target.value;
        this.setState({ name: name })
    }

    addressOnChange = (event) => {
        let address = event.target.value;
        this.setState({ address: address })
    }

    confirmOnClick = () => {
        let State = this.state.State;
        let payment = this.state.payment;
        let name = this.state.name;
        let address = this.state.address;
        let email = this.props.user.email;

        if (State.length === 0 || State.length === 'Choose') {
            cogoToast.error("Please Select State", { position: 'top-right' });
        }
        else if (payment.length === 0 || payment.length === 'Choose') {
            cogoToast.error("Please Select Payment", { position: 'top-right' });
        }
        else if (name.length === 0) {
            cogoToast.error("Please Select Your Name", { position: 'top-right' });
        }
        else if (address.length === 0) {
            cogoToast.error("Please Select Your Address", { position: 'top-right' });
        }
        else {
            let invoice = new Date().getTime();
            let MyFromData = new FormData();
            MyFromData.append('State', State)
            MyFromData.append('payment_method', payment)
            MyFromData.append('name', name)
            MyFromData.append('delivery_address', address)
            MyFromData.append('email', email)
            MyFromData.append('invoice_no', invoice)
            MyFromData.append('delivery_charge', "00");

            axios.post(AppURL.CartOrder, MyFromData).then(response => {

                if (response.data === 1) {
                    cogoToast.success("Order Request Received", { position: 'top-right' });
                    this.setState({ PageRedirectStaus: true })
                } else {
                    cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });
                }

            }).catch(error => {
                cogoToast.error("Your Request is not done ! Try Aagain", { position: 'top-right' });

            });
        }

    } // edn confim order method 
    PageRedirect = () => {
        if (this.state.PageRedirectStaus === true) {
            return (
                <Redirect to="/orderlist" />
            )

        } // edn confim order method 

    }


    render() {
        if(!localStorage.getItem('token')){
            return <Redirect to="/login" />
       }
       
        let totalPriceSum = 0;
        const MyList = this.state.ProductData;
        const MyView = MyList.map((ProductList, i) => {
            totalPriceSum = totalPriceSum + parseInt(ProductList.total_price);
            return <div>
                <Card >
                    <Card.Body>
                        <Row>
                            <Col md={3} lg={3} sm={6} xs={6}>
                                <img className="cart-product-img" src={ProductList.image} />
                            </Col>

                            <Col md={6} lg={6} sm={6} xs={6}>
                                <h5 className="product-name">{ProductList.product_name}</h5>
                                <h6> Quantity = {ProductList.quantity} </h6>
                                <p>{ProductList.size} | {ProductList.color}</p>
                                <h6>Price = {ProductList.unit_price} x {ProductList.quantity} = {ProductList.total_price}$</h6>
                            </Col>

                            <Col md={2} lg={2} sm={12} xs={12}>

                                <Button onClick={() => this.removeItem(ProductList.id)} className="btn btn-block w-100 mt-3  site-btn"><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>
                                <Button onClick={() => this.ItemPlus(ProductList.id, ProductList.quantity, ProductList.unit_price)} className="btn mt-2 mx-1 btn-lg site-btn"><FontAwesomeIcon icon={faPlus} size="lg" /> </Button>
                                <Button onClick={() => this.ItemMinus(ProductList.id, ProductList.quantity, ProductList.unit_price)} className="btn mt-2 mx-1 btn-lg site-btn"><FontAwesomeIcon icon={faMinus} size="lg" /> </Button>

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        })

        return (
            <Fragment>
                <Container>
                    <div className="section-title text-center mb-55"><h2>Product Cart List</h2>
                    </div>

                    <Row>
                        <Col className="p-1" lg={7} md={7} sm={12} xs={12} >
                            {MyView}
                        </Col>
                        <Col className="p-1" lg={5} md={5} sm={12} xs={12} >
                            <div className="card p-2">
                                <div className="card-body">
                                    <div className="container-fluid ">
                                        <div className="row">
                                            <div className="col-md-12 p-1  col-lg-12 col-sm-12 col-12">
                                                <h5 className="Product-Name text-danger FinalPrice"><b>Total Due: {totalPriceSum}  $</b></h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                                <label className="form-label"><b>Choose State</b></label>
                                                <select onChange={this.StateOnChange} className="form-control" style={{ width: '130px' }}>
                                                    <option value="">Choose</option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska </option>
                                                    <option value="Arizona">Arizona </option>
                                                    <option value="Arkansas">Arkansas </option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware </option>
                                                    <option value="Florida">Florida </option>
                                                    <option value="Georgia">Georgia </option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana </option>
                                                    <option value="Iowa">Iowa </option>
                                                    <option value="Kansas">Kansas </option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland </option>
                                                    <option value="Massachusetts">Massachusetts </option>
                                                    <option value="Michigan">Michigan </option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana </option>
                                                    <option value="Nebraska">Nebraska </option>
                                                    <option value="Nevada">Nevada </option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York </option>
                                                    <option value="North Carolina">North Carolina </option>
                                                    <option value="North Dakota">North Dakota </option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania </option>
                                                    <option value="Rhode Island">Rhode Island </option>
                                                    <option value="South Carolina">South Carolina </option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah </option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                                <label className="mt-2"><b>Choose Payment Method</b></label>
                                                <select onChange={this.paymentMethodOnChange} className="form-control" style={{ width: '130px' }}>
                                                    <option value="">Choose</option>
                                                    <option value="Crypto">Crypto</option>
                                                    <option value="Cerdit Cart">Cerdit Cart</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                                <label className="form-label"><b>Your Name</b></label>
                                                <input onChange={this.nameOnChange} className="form-control" type="text" placeholder="" style={{ width: '250px' }} />
                                            </div>

                                            <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                                <label className="form-label"><b>Delivery Address</b></label>
                                                <textarea onChange={this.addressOnChange} rows={2} className="form-control" type="text" placeholder="" />
                                            </div>
                                            <div className="col-md-12 p-1 col-lg-12 col-sm-12 col-12">
                                                <button onClick={this.confirmOnClick} className="btn  site-btn">{this.state.confirmBtn} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.PageRefresh()}
                {this.PageRedirect()}

            </Fragment>
        )
    }
}
export default Cart










//     render() {
//         return (
//             <Fragment>
//                 <Container>
//                     <div className="section-title text-center mb-55"><h2>Product Cart List</h2>
//                     </div>
//                     <Row>
//                         <Col className="p-1" lg={12} md={12} sm={12} xs={12} >
//                             <Card >
//                                 <Card.Body>
//                                     <Row>
//                                         <Col md={3} lg={3} sm={6} xs={6}>
//                                             <img className="w-100 h-100" src={Product1} alt="#" />
//                                         </Col>

//                                         <Col md={6} lg={6} sm={6} xs={6}>
//                                             <h5 className="product-name">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
//                                             <h6> Quantity = 05 </h6>
//                                             <h6>Price = 05 x 100 = 5000$</h6>
//                                         </Col>

//                                         <Col md={3} lg={3} sm={12} xs={12}>
//                                             <input placeholder="2" className="form-control text-center" type="number" />
//                                             <Button className="btn btn-block w-100 mt-3  site-btn"><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                         </Col>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col className="p-1" lg={12} md={12} sm={12} xs={12} >
//                             <Card >
//                                 <Card.Body>
//                                     <Row>
//                                         <Col md={3} lg={3} sm={6} xs={6}>
//                                             <img className="w-100 h-100" src={Product1} alt="#"/>
//                                         </Col>

//                                         <Col md={6} lg={6} sm={6} xs={6}>
//                                             <h5 className="product-name">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
//                                             <h6> Quantity = 05 </h6>
//                                             <h6>Price = 05 x 100 = 5000$</h6>
//                                         </Col>

//                                         <Col md={3} lg={3} sm={12} xs={12}>
//                                             <input placeholder="2" className="form-control text-center" type="number" />
//                                             <Button className="btn btn-block w-100 mt-3  site-btn"><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                         </Col>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </Col>



//                         <Col className="p-1" lg={12} md={12} sm={12} xs={12} >
//                             <Card >
//                                 <Card.Body>
//                                     <Row>
//                                         <Col md={3} lg={3} sm={6} xs={6}>
//                                             <img className="w-100 h-100" src={Product1} alt="#"/>
//                                         </Col>

//                                         <Col md={6} lg={6} sm={6} xs={6}>
//                                             <h5 className="product-name">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
//                                             <h6> Quantity = 05 </h6>
//                                             <h6>Price = 05 x 100 = 5000$</h6>
//                                         </Col>

//                                         <Col md={3} lg={3} sm={12} xs={12}>
//                                             <input placeholder="2" className="form-control text-center" type="number" />
//                                             <Button className="btn btn-block w-100 mt-3  site-btn"><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                         </Col>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col className="p-1" lg={12} md={12} sm={12} xs={12} >
//                             <Card >
//                                 <Card.Body>
//                                     <Row>
//                                         <Col md={3} lg={3} sm={6} xs={6}>
//                                             <img className="w-100 h-100" src={Product1}alt="#" />
//                                         </Col>

//                                         <Col md={6} lg={6} sm={6} xs={6}>
//                                             <h5 className="product-name">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
//                                             <h6> Quantity = 05 </h6>
//                                             <h6>Price = 05 x 100 = 5000$</h6>
//                                         </Col>

//                                         <Col md={3} lg={3} sm={12} xs={12}>
//                                             <input placeholder="2" className="form-control text-center" type="number" />
//                                             <Button className="btn btn-block w-100 mt-3  site-btn"><FontAwesomeIcon icon={faTrash} size="lg" /> Remove </Button>

//                                         </Col>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col className="p-1" lg={12} md={12} sm={12} xs={12} >
//                             <Card >
//                                 <Card.Body>
//                                     <Row>
//                                         <Col md={4} lg={4} sm={6} xs={6}>

//                                             <h5> Total Item = 05 </h5>
//                                             <h5>Total Price = 5000$</h5>
//                                             <Button className="btn btn-block w-100 mt-3  site-btn"><FontAwesomeIcon icon={faCartShopping} size="lg" /> CheckOut </Button>
//                                         </Col>
//                                     </Row>
//                                 </Card.Body>
//                             </Card>
//                         </Col>



//                     </Row>
//                 </Container>
//             </Fragment>
//         )
//     }
// }

// export default Cart
