import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppURL from '../../api/AppURL';
import axios from 'axios'
import NewArrivalLoading from '../PlaceHolder/NewArrivalLoading';


class NewArrival extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this)
        this.state = {
            ProductData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        axios.get(AppURL.ProductListByRemark("NEW")).then(response => {

            this.setState({ ProductData: response.data });
            this.setState({
                ProductData: response.data, isLoading: "d-none",
                mainDiv: ""
            });
        }).catch(error => {

        });
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 400,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const NewList = this.state.ProductData;
        const MyView = NewList.map((NewList, i) => {
            let Delivery;
            if (NewList.delivery === "free" || NewList.delivery === "Free"|| NewList.delivery === "Yes") {
                Delivery = <p className="product-delivery-on-card">Free Delivery</p>
            }
            if (NewList.special_price === "" || NewList.special_price === null) {
                return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                    <Link to={"/productdetails/"+NewList.id} style={{ textDecoration: 'none' }}>
                        <Card className="image-box card">
                            <img className="center" src={NewList.image} alt="FeaturedList" />
                            <Card.Body>
                                <p className="product-name-on-card">{NewList.title}</p>
                                <p className="product-price-on-card">Price : ${NewList.price}</p>
                                {Delivery}

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
            else {

                return <Col className="p-1" key={1} xl={2} lg={2} md={2} sm={4} xs={6}>
                    <Link to={"/productdetails/"+NewList.id} style={{ textDecoration: 'none' }}>
                        <Card className="image-box card">
                            <img className="center" src={NewList.image} alt="FeaturedList" />
                            <Card.Body >
                                <p className="product-name-on-card">{NewList.title}</p>
                                <p className="product-price-on-card">Price : <strike className="text-secondary">${NewList.price}</strike>&nbsp; ${NewList.special_price}</p>
                                {Delivery}

                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            }
        });
        return (
            <Fragment>
                <NewArrivalLoading isLoading={this.state.isLoading} />

                <div className={this.state.mainDiv}>
                    <Container className="text-center" fluid={true}>
                        <div className="section-title text-center mb-55"><h2>NEW ARRIVALS&nbsp;
                            <a className="btn btn-sm ml-2 site-btn" onClick={this.previous} href={() => false}><FontAwesomeIcon icon={faAngleLeft} size="lg" /></a>
                            &nbsp;
                            <a className="btn btn-sm ml-2 site-btn" onClick={this.next} href={() => false}><FontAwesomeIcon icon={faAngleRight} size="lg" /></a>
                            &nbsp;
                        </h2>
                            <p>Some Of Our New Arrivals, You May Like</p>
                        </div>
                        <Row>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {MyView}
                            </Slider>
                        </Row>
                    </Container>
                </div>
            </Fragment >
        )
    }
}

// return (
//     <Fragment>
//         <Container fluid={true} className="text-center" >
//             <div className="section-title text-center mb-55"><h2>NEW ARRIVAL
//                 <a className="btn btn-sm ml-2 site-btn" onClick={this.previous} href={() => false}><FontAwesomeIcon icon={faAngleLeft} size="lg" /></a>
//                 &nbsp;
//                 <a className="btn btn-sm ml-2 site-btn" onClick={this.next} href={() => false}><FontAwesomeIcon icon={faAngleRight} size="lg" /></a>
//                 &nbsp;
//             </h2>
//                 <p>Some Of Our Exclusive Collection, You May Like</p>
//             </div>

//             <Row>
//                 <Slider ref={c => (this.slider = c)} {...settings}>


//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival1.webp')} alt="Top Offers" />
//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival2.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival3.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival4.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival5.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival6.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival7.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                     <div>
//                         <Card className="image-box card">
//                             <img className="center"
//                                 src={require('../../../src/assets/images/Arrival8.webp')} alt="Top Offers" />

//                             <Card.Body>
//                                 <p className="product-name-on-card">Realme C21 (Cross Black, 64 GB)</p>
//                                 <p className="product-price-on-card">Price : $120</p>

//                             </Card.Body>
//                         </Card>
//                     </div>
//                 </Slider>
//             </Row>
//         </Container>

//     </Fragment>
// )
//     }
// }

export default NewArrival