import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import validation from '../../validation/validation';
import axios from 'axios'
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            phone: "",
            email: "",
            message: "",
        }
    }
    nameOnChange = (event) => {
        let name = event.target.value;
        this.setState({ name: name })
    }

    phoneOnChange = (event) => {
        let phone = event.target.value;
        this.setState({ phone: phone })

    }
    emailOnChange = (event) => {
        let email = event.target.value;
        this.setState({ email: email })
    }
    messageOnChange = (event) => {
        let message = event.target.value;
        this.setState({ message: message })
    }
    onFormSubmit = (event) => {

        let name = this.state.name;
        let phone = this.state.phone;
        let email = this.state.email;
        let message = this.state.message;
        let sendBtn = document.getElementById('sendBtn');
        let contactForm = document.getElementById('contactForm');
        if (phone.length === 0) {
            phone = 'Null';
        }

        if (name.length === 0) {
            // alert("Please write down your name");
            toast.error("Please write down your name");

            event.preventDefault();
        } else if (!(validation.NameRegx).test(name)) {
            // alert("Invaid Name");
            toast.error("Invaid Name");

            event.preventDefault();
        } else if (email.length === 0) {
            // alert("Please write down your Email");
            toast.error("Please write down your Email");
            event.preventDefault();
        } else if (message.length === 0) {
            // alert("Please write your message");
            toast.error("Please write your message");

            event.preventDefault();
        } else {


            let MyFormData = new FormData();
            MyFormData.append("name", name)
            MyFormData.append("phone", phone)
            MyFormData.append("email", email)
            MyFormData.append("message", message)
            sendBtn.innerHTML = "Sending...";


            axios.post(AppURL.PostContact, MyFormData).then(function (response) {
                if (response.status === 200 && response.data === 1) {
                    // alert("Message Send Successfully");
                    toast.success("Message Send Successfully");
                    contactForm.reset();
                    sendBtn.innerHTML = "Send";
                    // event.preventDefault();

                }
                else {
                    alert("error, please send it again");
                    toast.error("error");
                    sendBtn.innerHTML = "Send";
                    event.preventDefault();

                }


            }).catch(error => {
                    // alert(error);
                    toast.error(error);
                    sendBtn.innerHTML = "Send";
                    event.preventDefault();

                });
        }
        event.preventDefault();

    }




    render() {
        return (
            <Fragment>
                <Container>
                    <Row className="p-2">
                        <Col className="shadow-sm bg-white mt-2 me-5" md={12} lg={12} sm={12} xs={12}>

                            <Row className="text-center">
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form id="contactForm" onSubmit={this.onFormSubmit} className="onboardForm">
                                        <h4 className="section-title-login"> CONTACT WITH US </h4>
                                        <h6 className="section-sub-title">Please contact with us</h6>
                                        <input onChange={this.nameOnChange} className="form-control m-3" type="text" placeholder="Please enter your full Name" />
                                        <input onChange={this.phoneOnChange} className="form-control m-3" type="text" id="phone" name="phone" pattern=".{10,}" placeholder="Please enter your ten digit Phone Number or leave this field blank"
                                        />
                                        <input onChange={this.emailOnChange} className="form-control m-3" type="email" placeholder="please enter your Email" />
                                        <textarea onChange={this.messageOnChange} className="form-control m-3" type="textarea" placeholder="Please enter your Message" rows={6}></textarea>
                                        <Button id="sendBtn" type="submit" className="btn btn-block m-2 site-btn-login"> Next</Button>
                                    </Form>
                                </Col>
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Row className="onboardBanner">
                                        <p className=" section-title-contact">
                                            <br /><br /><br/>
                                            Mailing Address:<br />
                                            6285 E Spring St #308, Long Beach<br />
                                            CA 90808
                                            <br />
                                            Email: Support@CoverLoop.com
                                            <br /><br />
                                        </p>
                                        <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.0364962124363!2d-118.11106558488412!3d33.81137123783268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2e0102425ecd%3A0xbc9d9048f86c074a!2s6285%20E%20Spring%20St%2C%20Long%20Beach%2C%20CA%2090808!5e0!3m2!1sen!2sus!4v1663752955457!5m2!1sen!2sus" width="600" height="450" styles="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Contact
